import React, { createContext, useContext, useEffect, useState } from 'react';

interface ITelegramWebApp {
  initDataUnsafe: {
    user?: {
      id: number;
      first_name: string;
      last_name?: string;
      username?: string;
      language_code: string;
    };
    // Добавьте другие поля, если они вам нужны
  };
  // Добавьте другие методы и свойства Telegram Web App, которые вам могут понадобиться
}

interface ITelegramContext {
  webApp: ITelegramWebApp | null;
  setWebApp: React.Dispatch<React.SetStateAction<ITelegramWebApp | null>>;
}

const TelegramContext = createContext<ITelegramContext | undefined>(undefined);

export const useTelegram = () => {
  const context = useContext(TelegramContext);
  if (context === undefined) {
    throw new Error('useTelegram must be used within a TelegramProvider');
  }
  return context;
};
export const TelegramProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [webApp, setWebApp] = useState<ITelegramWebApp | null>(null);

  useEffect(() => {
    const app = (window as any).Telegram?.WebApp;
    console.log('app', app);
    if (app) {
      app.ready();
      setWebApp(app);
    }
  }, []);

  return (
    <TelegramContext.Provider value={{ webApp, setWebApp }}>
      {children}
    </TelegramContext.Provider>
  );
};