// Исправленная версия компонента с корректным выбором чекбоксов
import React, { useState, useEffect } from 'react';
import axios, { AxiosInstance } from 'axios';
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Select,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Stack,
  StackDivider,
  useToast,
  Text,
} from '@chakra-ui/react';
import { useTelegram } from './TelegramProvider';
import { TelegramChannel } from '../types/types';

interface RedditSource {
  name: string;
  type: 'reddit';
  reddit_id: string;
}

interface Filter {
  type: 'keyword';
  value: string;
}

interface ChannelFilters {
  channel_id: number;
  include_filters: Filter[];
  exclude_filters: Filter[];
}

// Классы для работы с API
export interface IApiClient {
  get<T>(url: string, params?: any): Promise<T>;
  post<T>(url: string, data: any): Promise<T>;
  put<T>(url: string, data: any): Promise<T>;
  delete<T>(url: string): Promise<T>;
}

// ApiClient remains unchanged
export class ApiClient {
  private static instance: ApiClient;
  private axiosInstance: AxiosInstance;

  constructor(baseURL: string) {
    this.axiosInstance = axios.create({
      baseURL,
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  async get<T>(url: string, params?: any): Promise<T> {
    const response = await this.axiosInstance.get<T>(url, { params });
    return response.data;
  }

  async post<T>(url: string, data: any): Promise<T> {
    const response = await this.axiosInstance.post<T>(url, data);
    return response.data;
  }

  async put<T>(url: string, data: any): Promise<T> {
    const response = await this.axiosInstance.put<T>(url, data);
    return response.data;
  }

  async delete<T>(url: string): Promise<T> {
    const response = await this.axiosInstance.delete<T>(url);
    return response.data;
  }
}



// Renamed ChannelService to UserService
export interface IUserService {
  getChannelsByUserId(userId: number): Promise<TelegramChannel[]>;
}

export class UserService implements IUserService {
  private static instance: UserService;
  private apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }

  async getChannelsByUserId(userId: number): Promise<TelegramChannel[]> {
    return this.apiClient.get<TelegramChannel[]>(`/api/v1/events/channels/${userId}`);
  }
}



export interface IAutopostingService {
  createSource(source: RedditSource): Promise<any>;
  addSourceToChannel(channelId: number, sourceId: string): Promise<any>;
  createFilters(filters: ChannelFilters): Promise<any>;
  getAvailableFlairs(subreddit: string): Promise<string[]>;
}

export class AutopostingService implements AutopostingService {
  private static instance: AutopostingService;
  private apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }

  async createSource(source: RedditSource): Promise<any> {
    return this.apiClient.post<any>('/sources', source);
  }

  async addSourceToChannel(channelId: number, sourceId: string): Promise<any> {
    return this.apiClient.post<any>(`/channels/${channelId}/sources`, { source_id: sourceId });
  }

  async createFilters(filters: ChannelFilters): Promise<any> {
    return this.apiClient.post<any>('/filters', filters);
  }

  async getAvailableFlairs(subreddit: string): Promise<string[]> {
    return this.apiClient.get<string[]>(`/filters/available-flairs/${subreddit}`);
  }
}

// Фабрика для создания сервисов
export class ServiceFactory {
  static createUserService(baseURL: string): IUserService {
    const apiClient = new ApiClient(baseURL);
    return new UserService(apiClient);
  }

  static createAutopostingService(baseURL: string): IAutopostingService {
    const apiClient = new ApiClient(baseURL);
    return new AutopostingService(apiClient);
  }
}

// Основной компонент настройки автопостинга
const AutopostingSetup: React.FC = () => {
  const { webApp } = useTelegram();
  const [channels, setChannels] = useState<TelegramChannel[]>([]);
  const [selectedChannel, setSelectedChannel] = useState<number | null>(null);
  const [redditUrl, setRedditUrl] = useState('');
  const [availableFlairs, setAvailableFlairs] = useState<string[]>([]);
  const [selectedFlairs, setSelectedFlairs] = useState<string[]>([]);
  const toast = useToast();


  const API_BASE_URL_8111 = '/api8111';
  const API_BASE_URL_8110 = '/api8110';

  const userService = ServiceFactory.createUserService(API_BASE_URL_8111);
  const autopostingService = ServiceFactory.createAutopostingService(API_BASE_URL_8110);


  useEffect(() => {
    const fetchChannels = async () => {
      if (!webApp || !webApp.initDataUnsafe.user) {
        console.error('User data is not available');
        return;
      }

      const userId = webApp.initDataUnsafe.user.id;

      try {
        const fetchedChannels = await userService.getChannelsByUserId(userId);
        setChannels(fetchedChannels);
      } catch (error) {
        console.error('Error fetching channels:', error);
        toast({
          title: 'Ошибка',
          description: 'Не удалось загрузить список каналов',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchChannels();
  }, [webApp, toast]);

  const handleChannelSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedChannel(Number(e.target.value));
  };


  useEffect(() => {
    if (redditUrl) {
      const subreddit = extractSubreddit(redditUrl);
      if (subreddit) {
        fetchAvailableFlairs(subreddit);
      }
    }
  }, [redditUrl]);

  const extractSubreddit = (url: string): string | null => {
    const match = url.match(/reddit\.com\/r\/([^/]+)/);
    return match ? match[1] : null;
  };


  const fetchAvailableFlairs = async (subreddit: string) => {
    try {
      const flairs = await autopostingService.getAvailableFlairs(subreddit);
      setAvailableFlairs(flairs);
    } catch (error) {
      console.error('Error fetching flairs:', error);
    }
  };

  const handleRedditUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRedditUrl(e.target.value);
  };

  const handleFlairToggle = (flair: string) => {
    console.log('flair', flair);
    setSelectedFlairs((prev) =>
      prev.includes(flair) ? prev.filter((f) => f !== flair) : [...prev, flair]
    );
  };

  const handleSubmit = async () => {
    if (!selectedChannel || !redditUrl) {
      toast({
        title: 'Ошибка',
        description: 'Пожалуйста, выберите канал и введите URL Reddit',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const subreddit = extractSubreddit(redditUrl);
      if (!subreddit) {
        toast({
          title: 'Ошибка',
          description: 'Неверный URL Reddit',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      // Создание источника
      const source = await autopostingService.createSource({
        name: subreddit,
        type: 'reddit',
        reddit_id: subreddit,
      });

      // Привязка источника к каналу
      await autopostingService.addSourceToChannel(selectedChannel, source.id);

      // Создание фильтров
      await autopostingService.createFilters({
        channel_id: selectedChannel,
        include_filters: selectedFlairs.map((flair) => ({
          type: 'keyword',
          value: flair,
        })),
        exclude_filters: [],
      });

      toast({
        title: 'Успешно',
        description: 'Настройки сохранены успешно!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error saving settings:', error);
      toast({
        title: 'Ошибка',
        description: 'Произошла ошибка при сохранении настроек',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Card maxW="md" mx="auto" mt="10" p="6">
      <CardHeader>
        <Heading size="md">Настройка автопостинга</Heading>
        <Text fontSize="sm" color="gray.500"> user: {webApp?.initDataUnsafe.user?.id}</Text>
      </CardHeader>
      <CardBody>
        <Stack spacing="4" divider={<StackDivider />}>
          <FormControl id="channel-select">
            <FormLabel>Выберите Telegram канал</FormLabel>
            <Select placeholder="Выберите канал" onChange={handleChannelSelect}>
              {channels.map((channel) => (
                <option key={channel.id} value={channel.id}>
                  {channel.name}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl id="reddit-url">
            <FormLabel>Введите ссылку на Reddit сообщество</FormLabel>
            <Input
              placeholder="https://reddit.com/r/..."
              value={redditUrl}
              onChange={handleRedditUrlChange}
            />
          </FormControl>

          {availableFlairs.length > 0 && (
              <Stack spacing="2">
                <Text fontWeight="bold">Выберите флаиры</Text>
                {availableFlairs.map((flair) => (
                  <Checkbox
                    key={flair}
                    isChecked={selectedFlairs.includes(flair)}
                    onChange={() => handleFlairToggle(flair)}
                  >
                    {flair}
                  </Checkbox>
                ))}
              </Stack>
          )}

          <Button colorScheme="teal" width="full" onClick={handleSubmit}>
            Сохранить настройки
          </Button>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default AutopostingSetup;