import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import AutopostingSetup from './components/AutopostingSetup';
import { TelegramProvider } from './components/TelegramProvider';
// import DebugModeSwitcher from './components/DebugModeSwitcher';


function App() {
  return (
    <TelegramProvider>
      <ChakraProvider>
        {/* <DebugModeSwitcher /> */}
        <AutopostingSetup />
      </ChakraProvider>
    </TelegramProvider>
  );
}

export default App;